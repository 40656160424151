import React, { useEffect } from 'react';
import '../styles/Introduction.css';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Lessons = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);

  return (
    <div >
      <div className="intro-section">
        <div className="intro-image">
          <Link to="/lessons">
            <img src="/images/lessons.avif" alt="Lessons" />
          </Link>
        </div>
        <div className="intro-content">
          <p>
            <Link style={{ color: '#0069d2' }} to="/lessons">
              <b>Software Architecture Almanac</b>
            </Link>{' '}
            provides in-depth lessons on application design, integration strategies, and enterprise architecture. 
            Each lesson delivers practical insights and best practices to help you design scalable systems, refine your architectural skills, and stay ahead in your career.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
