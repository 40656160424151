// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutMePage from './pages/AboutMePage';
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import MyAccount from './profile/MyAccount';

import AdminRoute from './authentication/AdminRoute';
import ResourcesPage from './pages/ResourcesPage';
import ManageUsers from './profile/ManageUsers';
import SendLessonEmail from './profile/SendLessonEmail';

import LessonsPage from './pages/LessonsPage';
import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';
import Lesson3 from './lessons/Lesson3';
import Lesson4 from './lessons/Lesson4';
import Lesson5 from './lessons/Lesson5';
import Lesson6 from './lessons/Lesson6';
import Lesson7 from './lessons/Lesson7';

import ChallengesPage from './pages/ChallengesPage';
import Challenge1 from './challenges/Challenge1';
import Challenge2 from './challenges/Challenge2';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/contact" element={<ContactMePage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/auth/google/callback" element={<SignInPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/resources" element={<ResourcesPage />} />  
        <Route path="/manage-users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
        <Route path="/send-lesson-email" element={<AdminRoute><SendLessonEmail /></AdminRoute>} />

        <Route path="/lessons" element={<LessonsPage />} />
        <Route path="/lessons/lesson1" element={<Lesson1 />} />
        <Route path="/lessons/lesson2" element={<Lesson2 />} />
        <Route path="/lessons/lesson3" element={<Lesson3 />} />
        <Route path="/lessons/lesson4" element={<Lesson4 />} />
        <Route path="/lessons/lesson5" element={<Lesson5 />} />
        <Route path="/lessons/lesson6" element={<Lesson6 />} />
        <Route path="/lessons/lesson7" element={<Lesson7 />} />


        <Route path="/challenges" element={<ChallengesPage />} />
        <Route path="/challenges/challenge1" element={<Challenge1 />} />
        <Route path="/challenges/challenge2" element={<Challenge2 />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
