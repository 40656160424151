import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import ReactGA from 'react-ga4';
import IntroductionSection from '../sections/IntroductionSection';
import LessonsSection from '../sections/LessonsSection';
import ChallengesSection from '../sections/ChallengesSection';
import ResourcesSection from '../sections/ResourcesSection';

const HomePage = () => {
    useEffect(() => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Visit Home Page',
            label: 'Home Page',
        });
    }, []);

    return (
        <div className="homepage">
            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-content">
                    <h1 className="hero-title">Insight Veda</h1>
                    <p className="hero-tagline">
                        Transforming <span className="hero-highlight">Knowledge</span> into Impactful Growth
                    </p>
                    <p className="hero-subtext">
                        Master <b>Software Architecture</b> through expert lessons, real-world challenges, and curated resources.
                    </p>
                    <Link to="/lessons" className="hero-button">Explore Lessons</Link>
                </div>
            </section>

            {/* Linear Content Sections */}
            <section className="content-sections">
                <div className="section-item"><IntroductionSection /></div>
                <div className="section-item"><LessonsSection /></div>
                <div className="section-item"><ChallengesSection /></div>
                <div className="section-item"><ResourcesSection /></div>
            </section>
        </div>
    );
}

export default HomePage;
