import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Challenges = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenges Section',
      label: 'Challenges Page',
    });
  }, []);
  return (
    <div>
            <div className="intro-section">
              <div className="intro-image">
                <Link to="/challenges">
                  <img src="/images/challenges.avif" alt="Challenges" />
                </Link>
              </div>
              <div className="intro-content">
                <p>
                  <Link style={{ color: '#0069d2' }} to="/challenges">
                    <b>Software Architect's Challenges</b>
                  </Link>{' '}
                  presents real-world scenarios to test and refine your architectural skills. Tackle challenges on scalability, system design, and resilient architectures while gaining actionable insights to sharpen your problem-solving abilities and elevate your expertise.
                </p>
              </div>
            </div>
        </div>
  );
}

export default Challenges;
