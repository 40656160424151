import React, { useState } from 'react';
import axios from 'axios';
import '../styles/SendLessonEmail.css';

const SendLessonEmail = () => {
  const [lessonContent, setLessonContent] = useState('');
  const [lessonNumber, setLessonNumber] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handle sending lesson emails
  const handleSendEmails = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!lessonContent || !lessonNumber || !lessonTitle) {
      setError('Please provide lesson content, lesson title and a lesson number.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE_API_URL}/api/subscriptions/send-lessons`,
        { content: lessonContent, lessonNumber: lessonNumber, title: lessonTitle },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Lesson email sent to all verified subscribers successfully.');
      }
    } catch (err) {
      console.error('Error sending lesson emails:', err);
      setError('Failed to send emails. Please try again later.');
    }
  };

  return (
    <div className="send-lesson-email-container">
      <h2>Send Lesson Email</h2>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <form onSubmit={handleSendEmails} className="lesson-email-form">
        <div className="form-group">
          <label htmlFor="lessonNumber">Lesson Number</label>
          <input
            type="text"
            id="lessonNumber"
            value={lessonNumber}
            onChange={(e) => setLessonNumber(e.target.value)}
            placeholder="Enter lesson number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lessonTitle">Lesson Title</label>
          <input
            type="text"
            id="lessonTitle"
            value={lessonTitle}
            onChange={(e) => setLessonTitle(e.target.value)}
            placeholder="Enter lesson title"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lessonContent">Lesson Content</label>
          <textarea
            id="lessonContent"
            value={lessonContent}
            onChange={(e) => setLessonContent(e.target.value)}
            placeholder="Enter lesson content here..."
            required
            style={{
              width: '100%',
              height: '150px', // Adjust height to make it larger
              resize: 'vertical', // Allow resizing vertically if needed
              padding: '10px', // Add some padding for better readability
              fontSize: '16px', // Improve font size for better readability
            }}
          />
        </div>
        <div className="send-lesson-container">
              <button type="submit" className="send-lesson-button">Send Email</button>
        </div>

      </form>
    </div>
  );
};

export default SendLessonEmail;
