import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge1 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 1 Page',
      label: 'Challenge 1 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 1: Implementing Authentication in Microservices</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            Authentication is a critical aspect of any microservices-based architecture. It ensures that only authorized users and services can access resources, enhancing security and preventing unauthorized access.
            For this challenge, you will design and implement a token-based authentication mechanism for a microservices application.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Create a microservices application with at least two services and implement token-based authentication using JWT (JSON Web Tokens) or an equivalent mechanism in your preferred technology stack.
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Service Structure:</strong>
              <ul className="small-bullet">
                <li>User Service: Handles user registration and login, issues authentication tokens.</li>
                <li>Product Service: Provides product information, accessible only to authenticated users.</li>
              </ul>
            </li>
            <li>
              <strong>Token Implementation:</strong>
              <ul className="small-bullet">
                <li>Use tokens (e.g., JWT, OAuth) for authentication.</li>
                <li>Add user roles (e.g., admin, user) for role-based access control.</li>
              </ul>
            </li>
            <li>
              <strong>APIs to Implement:</strong>
              <ul className="small-bullet">
                <li><span className="highlighted-text">/register</span>: Registers a new user.</li>
                <li><span className="highlighted-text">/login</span>: Authenticates the user and returns an authentication token.</li>
                <li><span className="highlighted-text">/products</span>: Fetches product details; requires authentication.</li>
              </ul>
            </li>
            <li>
              <strong>Token Validation:</strong>
              <ul className="small-bullet">
                <li>Implement middleware to validate the token.</li>
                <li>Restrict access to <span className="highlighted-text">/products</span> based on user roles.</li>
              </ul>
            </li>
            <li>
              <strong>Database Integration:</strong> Use any database to store user credentials and product details.
            </li>
            <li>
              <strong>Testing:</strong> Use Postman, Insomnia, or CURL to test your APIs.
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Set up User Service and Product Service.</li>
            <li>Implement user registration and login.</li>
            <li>Generate and issue tokens upon login.</li>
            <li>Protect the Product Service API endpoints using token validation.</li>
            <li>Implement role-based access control.</li>
            <li>Test with your preferred API testing tool.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Research token-based authentication mechanisms like JWT or OAuth.</li>
            <li>Use libraries that simplify token handling in your tech stack.</li>
            <li>Store secrets securely using environment variables.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine an e-commerce platform where:
            <ul>
              <li>The User Service handles user accounts and authentication.</li>
              <li>The Product Service provides a list of products but restricts access to logged-in users only.</li>
            </ul>
            <strong>Flow:</strong>
            <ul>
              <li>User registers via <span className="highlighted-text">/register</span>.</li>
              <li>User logs in via <span className="highlighted-text">/login</span> and receives a token.</li>
              <li>Token is used to access <span className="highlighted-text">/products</span>.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Add an Order Service to demonstrate service-to-service communication.</li>
            <li>Implement token expiration and a refresh token mechanism.</li>
            <li>Use a configuration server to manage shared configurations.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
          <h2>Resources</h2>
          <ul>
            <li><a href="https://jwt.io/" target="_blank" rel="noopener noreferrer">JWT Documentation</a></li>
            <li><a href="https://postman.com" target="_blank" rel="noopener noreferrer">Postman</a></li>
            <li>Other resources relevant to your tech stack.</li>
          </ul>
        </section>

        {/* Navigation Links */}
        {/* Redirections */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/challenges/challenge2">Next Challenge</a>
        
      </div>
    </div>
  );
};

export default Challenge1;
