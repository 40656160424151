import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/ChallengesPage.css';

const Challenge2 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Challenge 2 Page',
      label: 'Challenge 2 Page',
    });
  }, []);

  return (
    <div className="challenges-container">
      <div className="challenges-content">
        <h1>Challenge 2: Implementing Circuit Breaker in Microservices</h1>

        {/* Overview */}
        <section className="principle-section">
          <h2>Overview</h2>
          <p>
            In a microservices architecture, service failures can propagate and impact the entire system. A circuit breaker prevents cascading failures by detecting unhealthy services and stopping requests until they recover.
          </p>
        </section>

        {/* Objective */}
        <section className="principle-section">
          <h2>Objective</h2>
          <p>
            Implement a circuit breaker pattern in a microservices-based application to prevent excessive failures and enhance resilience.
          </p>
        </section>

        {/* Requirements */}
        <section className="principle-section">
          <h2>Requirements</h2>
          <ul className="big-bullet">
            <li>
              <strong>Service Structure:</strong>
              <ul className="small-bullet">
                <li>Order Service: Handles order processing.</li>
                <li>Payment Service: Simulates an external payment provider.</li>
              </ul>
            </li>
            <li>
              <strong>Implement Circuit Breaker:</strong>
              <ul className="small-bullet">
                <li>Use a circuit breaker library (e.g., Resilience4j for Java, Polly for .NET, Hystrix for Spring Boot).</li>
                <li>Configure failure thresholds, timeout settings, and automatic recovery.</li>
              </ul>
            </li>
            <li>
              <strong>APIs to Implement:</strong>
              <ul className="small-bullet">
                <li><span className="highlighted-text">/order</span>: Places a new order and triggers payment processing.</li>
                <li><span className="highlighted-text">/payment</span>: Simulates a payment transaction (intentionally fails intermittently).</li>
              </ul>
            </li>
            <li>
              <strong>Failure Handling:</strong>
              <ul className="small-bullet">
                <li>Simulate service failures and observe circuit breaker behavior.</li>
                <li>Provide fallback mechanisms when a service is unavailable.</li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Steps to Solve */}
        <section className="principle-section">
          <h2>Steps to Solve</h2>
          <ul>
            <li>Set up Order and Payment services.</li>
            <li>Introduce artificial failures in the Payment Service.</li>
            <li>Integrate a circuit breaker mechanism.</li>
            <li>Configure thresholds and recovery settings.</li>
            <li>Test behavior under failure conditions.</li>
          </ul>
        </section>

        {/* Hints */}
        <section className="principle-section">
          <h2>Hints</h2>
          <ul>
            <li>Understand how circuit breakers work and their state transitions (Closed, Open, Half-Open).</li>
            <li>Use monitoring tools to track circuit breaker events.</li>
            <li>Implement fallback responses to ensure better user experience.</li>
          </ul>
        </section>

        {/* Example Use Case */}
        <section className="principle-section">
          <h2>Example Use Case</h2>
          <p>
            Imagine an e-commerce platform where:
            <ul>
              <li>The Order Service processes new orders and triggers payments.</li>
              <li>The Payment Service may fail due to an external provider outage.</li>
            </ul>
            <strong>Flow:</strong>
            <ul>
              <li>User places an order via <span className="highlighted-text">/order</span>.</li>
              <li>Order Service calls Payment Service.</li>
              <li>If Payment Service fails repeatedly, the circuit breaker trips, preventing further calls.</li>
            </ul>
          </p>
        </section>

        {/* Bonus Points */}
        <section className="principle-section">
          <h2>Bonus Points</h2>
          <ul>
            <li>Integrate logging and monitoring for circuit breaker states.</li>
            <li>Use event-driven architecture to retry payments asynchronously.</li>
            <li>Implement exponential backoff for retries.</li>
          </ul>
        </section>

        {/* Resources */}
        <section className="principle-section">
        <h2>Resources</h2>
        <ul>
            <li><a href="https://github.com/Netflix/Hystrix" target="_blank" rel="noopener noreferrer">Hystrix GitHub Repository</a></li>
            <li><a href="https://resilience4j.readme.io/docs/circuitbreaker" target="_blank" rel="noopener noreferrer">Resilience4j Circuit Breaker Documentation</a></li>
            <li><a href="https://spring.io/guides/gs/cloud-circuit-breaker" target="_blank" rel="noopener noreferrer">Spring Cloud Circuit Breaker Guide</a></li>
            <li>Other resources relevant to your tech stack.</li>
        </ul>
        </section>



        {/* Navigation Links */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/challenges">Back To Challenges</a>
      </div>
    </div>
  );
};

export default Challenge2;
