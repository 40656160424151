// src/styles/theme.js

const theme = {
  colors: {
    primaryBackground: '#ffffff',  // White
    accent: '#04295b',             // Yellow Orange
    text: '#000000',               // Black
    whiteText: '#ffffff',          // White
    secondaryText: '#04295b',      // Yellow Orange
    secondaryBackground: '#f5f5f5', // Gray Light
    contentText: '#0069d2',         // Light Blue
    danger: '#fc5185',             // Danger
    warning: '#ffcd35',            // Warning
    success: '#00b090',            // Success
    azure: '#357df9',              // Azure
    social: '#04295b',             // Yellow Orange
  },
  fonts: {
    primary: "'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif",   // Primary Font: Roboto for a modern, professional look
    secondary: "'Inter', 'Arial', sans-serif",  // Secondary Font: Inter for clean and legible text
  },
  typography: {
    logo: {
      fontFamily: 'var(--font-primary)',
      fontWeight: '600',  // Slightly bolder for a more impactful logo
      fontSize: '3rem',   // Increased size for better visibility
      textShadow: '4px 4px 6px rgba(0, 0, 0, 0.2)', // Subtle shadow for professionalism
      color: 'var(--colors-text)',
    },
    h1: {
      fontSize: '50px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600', // Bold for better impact
      lineHeight: '1.2',
      mobileFontSize: '40px',
      color: 'var(--colors-contentText)',
    },
    h2: {
      fontSize: '45px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600', 
      lineHeight: '1.2',
      mobileFontSize: '36px',
    },
    h3: {
      fontSize: '35px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600',
      lineHeight: '1.2',
      mobileFontSize: '32px',
    },
    h4: {
      fontSize: '30px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600',
      lineHeight: '1.2',
      mobileFontSize: '28px',
    },
    h5: {
      fontSize: '25px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600',
      lineHeight: '1.2',
      mobileFontSize: '24px',
    },
    h6: {
      fontSize: '20px',
      fontFamily: 'var(--font-primary)',
      fontWeight: '600',
      lineHeight: '1.2',
      mobileFontSize: '20px',
    },
    body: {
      fontSize: '1.2rem',  // Updated font size as per your request
      fontFamily: 'var(--font-secondary)',
      fontWeight: '400', // Lighter weight for comfortable reading
      lineHeight: '1.2', // Updated line height as per your request
    },
    bodyLarge: {
      fontSize: '18px',
      fontFamily: 'var(--font-secondary)',
      fontWeight: '400',
      lineHeight: '1.45',
    },
    bodySmall: {
      fontSize: '15px',
      fontFamily: 'var(--font-secondary)',
      fontWeight: '400',  // Lighter for easier consumption
      lineHeight: '1.45',
    },
  },
  button: {
    primary: {
      fontSize: '16px',
      paddingX: '32px',
      paddingY: '16px',
      fontFamily: 'var(--font-secondary)',
      fontWeight: '500',
      borderRadius: '4px',  // Rounded corners for a polished look
      transition: '0.2s ease',
    },
    secondary: {
      fontSize: '16px',
      paddingX: '32px',
      paddingY: '16px',
      fontFamily: 'var(--font-secondary)',
      fontWeight: '500',
      borderRadius: '4px',  // Consistent rounded corners
      transition: '0.2s ease',
    },
  },
};

export default theme;
