import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SignInPage.css';
import ReactGA from 'react-ga4';
import { UserContext } from '../contexts/UserContext';
import { redirectToGoogleAuth, handleGoogleCallback } from '../auth/googleAuth';

const SignInPage = () => {
  const [apiError, setApiError] = useState('');
  const { isLoggedIn, setIsLoggedIn, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit SignIn Page',
      label: 'SignIn Page',
    });

    const storedToken = localStorage.getItem('token');
    if (storedToken && isLoggedIn) {
      navigate('/code-insights');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    
    // Ensure handleGoogleCallback is only called once
    if (authCode && !localStorage.getItem('authHandled')) {
      localStorage.setItem('authHandled', 'true'); // Mark as handled to prevent duplicate calls
      handleGoogleCallback(authCode)
        .then((tokens) => {
          if (!tokens || !tokens.id_token) {
            throw new Error('Invalid tokens received');
          }

          // Send the id_token to the backend for validation and sign-in
          return fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/google-signin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${tokens.id_token}`, // Send only the id_token
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Failed to sign in with Google.');
              }
              return response.json();
            })
            .then((data) => {
              // Store JWT token and user info locally
              localStorage.setItem('token', data.token);
              localStorage.setItem('user', JSON.stringify(data));

              setUser(data);
              setIsLoggedIn(true);

              navigate('/lessons'); // Redirect after successful sign-in
            });
        })
        .catch((error) => {
          setApiError('Failed to sign in with Google.');
          localStorage.removeItem('authHandled'); // Reset the marker on error
        });
    }
  }, [isLoggedIn, navigate, setUser, setIsLoggedIn]);

  const handleGoogleSignIn = async () => {
    redirectToGoogleAuth(); // Redirect to Google for authentication
  };

  return (
    <div className="sign-in-container">
      <h2>Welcome Back!</h2>
      <p1>To get started, sign in to your account.</p1>
      {apiError && <p className="error">{apiError}</p>}
      <button onClick={handleGoogleSignIn} className="google-sign-in-button">
        <img src="/images/google-icon.png" alt="Google Logo" className="google-logo" />
        Sign in with Google
      </button>
    </div>
  );
};

export default SignInPage;
