import React, { useEffect } from 'react';
import '../styles/ContactMePage.css'; // Add styling as necessary
import ReactGA from 'react-ga4';

const ContactMePage = () => {
  useEffect(() => {
    // Log the page visit to Google Analytics
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit ContactMe Page',
      label: 'ContactMe Page',
    });
  }, []);

  return (
    <div className="contact-me-container">
      <div className="contact-me-card">
        <p>For any collaboration, inquiries, or mentoring sessions, feel free to reach out at:</p>
        <div className="contact-details" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <p>
            <strong>Email:</strong>{' '}
            <a
              href="mailto:info@insightveda.com"
              target="_blank"
              className="social-link email"
              rel="noopener noreferrer"
            >
              info@insightveda.com
            </a>
          </p>
          <p>
            <strong>1:1 Connect:</strong>{' '}
            <button
              style={{
                backgroundColor: "#000",
                color: "#fff",
                border: "1px solid #000",
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
                borderRadius: "8px",
              }}
              onClick={() => window.open('https://topmate.io/bhuvnesh_arya', '_blank')}
            >
              Let's Connect
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactMePage;
