import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LessonsPage.css';
import ReactGA from 'react-ga4';

const LessonsPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Lessons Page',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Software Architecture Almanac</h1>
        <p><b style={{ fontStyle: 'italic' }}>Software Architecture Almanac with Bhuvnesh Arya</b>  is a free, in-depth resource designed to enhance your architectural expertise. Each lesson covers key topics such as application design, system integration, enterprise architecture, and best practices—providing practical insights to deepen your understanding and advance your skills. </p>
        <ul className="lessons-list">
          <li><Link to="/lessons/lesson7">Lesson 7: Cloud and Software Architecture for IoT Systems (Feb 13, 2025)</Link></li>
          <li><Link to="/lessons/lesson6">Lesson 6: Resilient Architecture Patterns for Microservices (Feb 02, 2025)</Link></li>
          <li><Link to="/lessons/lesson5">Lesson 5: API Gateway and Service Mesh Essentials (Jan 19, 2025)</Link></li>
          <li><Link to="/lessons/lesson4">Lesson 4: Event-Driven Architecture Essentials (Jan 02, 2025)</Link></li>
          <li><Link to="/lessons/lesson3">Lesson 3: Domain-Driven Design - Microservices Style (Dec 13, 2024)</Link></li>
          <li><Link to="/lessons/lesson2">Lesson 2: System Design Framework (Nov 2, 2024)</Link></li>
          <li><Link to="/lessons/lesson1">Lesson 1: Microservices Design Principles (Oct 5, 2024)</Link></li>
          {/* Add more lessons as needed */}
        </ul>
      </div>
    </div>
  );
};

export default LessonsPage;
